@use "src/_app/styles/mixins.scss" as *;
.slide {
  width: 302px !important;
  height: 580px !important;
  display: flex !important;
  flex-flow: column;
  justify-content: space-between;
  border-radius: 20px;
  padding: 20px 24px 24px;
  background: var(--black);
  gap: 20px;

  @include respond(tablet) {
    height: 472px !important;
    padding: 16px 24px;
  }

  &__text {
    min-height: 146px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    color: var(--white);

    h5 {
      margin: 0;

      @include Land_32;
    }

    span {
      @include tSubtitle;
    }
  }

  a,
  .disabled {
    margin-top: 80px;
    width: 254px;
    height: 56px;
    display: flex;
    justify-content: center;
    padding: 16px 24px;
    border-radius: 10px;
    background: var(--white);
    color: #0a0a0a;

    @include tSubtitle;

    @include respond(tablet) {
      margin-top: auto;
      width: 100%;
      height: 40px;
      padding: 8px 16px;
    }
  }

  .disabled {
    cursor: default;
    opacity: 0.5;
  }
}
