@use "src/_app/styles/mixins.scss" as *;
.badge {
  max-width: 100%;
  height: 30px;
  padding: 6px;
  border-radius: 28px;
  display: inline-flex;
  align-items: center;
  column-gap: 6px;
  user-select: none;
  justify-content: center;
  color: var(--white);

  &.green {
    background: var(--success);
  }

  &.red {
    background: #ee404f;
  }

  &.default {
    min-width: 69px;
    padding: 7px 8px;
    background: linear-gradient(
      203.46deg,
      rgb(33 33 33 / 60%) 31.22%,
      rgb(10 10 10 / 60%) 153.02%
    );
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .text {
    @include tTag;
  }
}
