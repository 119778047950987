@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  color: var(--white);

  .preview {
    display: flex;
    justify-content: space-between;

    @include respond(laptopC) {
      background-image: url('/img/main/sign-up-mobile-right-block-image.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom center;
    }

    @include respond(tabletS) {
      background-size: 372px 232px;
      background-position: center right;
    }

    &__left_block {
      display: flex;
      flex-flow: column;
      justify-content: center;
      gap: 24px;

      h2 {
        margin: 0 0 12px;
        font-size: 60px;
        font-weight: 700;
        line-height: 72px;

        @include respond(tablet) {
          font-size: 38px;
          line-height: 46px;
        }
      }

      a.signUp__button {
        width: max-content;
        min-width: 200px;
        height: 56px;
        padding: 16px 24px;
        border-radius: 10px;
        background-color: var(--white);
        color: #0a0a0a;
        text-align: center;

        @include tSubtitle;

        @include respond(tablet) {
          height: 40px;
          padding: 8px 16px;
          border-radius: 10px;
        }
      }

      &__links {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;

        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 100%;
            height: 100%;

            cursor: pointer;
          }
        }

        &__qr {
          position: absolute;
          top: 56px;
          margin-inline-start: 0;
          z-index: 5;
        }

        @media only screen and (width <= 600px) {
          max-width: 490px;
        }
      }
    }

    &__icon {
      @include respond(laptopC) {
        display: none;
      }
    }
  }

  .statistic {
    margin: 0 auto;
    max-width: 1062px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 39.33px;

    @include respond(tablet) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 9px;
    }

    &__item {
      padding: 16px 24px;
      display: flex;
      flex-flow: column;
      gap: 10px;
      border-radius: 20px;
      border: 1px solid #343434;
      background-color: #0a0a0a;
      text-align: center;

      @include respond(mobileL) {
        padding: 16px;
      }

      h3 {
        margin: 0;

        @include tTitle_1_semibold;

        @include respond(tablet) {
          font-size: 20px;
          line-height: 28px;
        }
      }

      span {
        @include tSubtitle;

        @include respond(tablet) {
          @include tCaption;
        }
      }
    }
  }
}
