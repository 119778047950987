@mixin respond($breakpoint) {
  @if $breakpoint == laptopXL {
    @media only screen and (width <= 2560px) {
      @content;
    }
  }

  @if $breakpoint == laptopL {
    @media only screen and (width <= 1440px) {
      @content;
    }
  }

  @if $breakpoint == laptopC {
    @media only screen and (width <= 1150px) {
      @content;
    }
  }

  @if $breakpoint == laptop {
    @media only screen and (width <= 1024px) {
      @content;
    }
  }

  @if $breakpoint == tabletL {
    @media only screen and (width <= 920px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (width <= 768px) {
      @content;
    }
  }

  @if $breakpoint == tabletS {
    @media only screen and (width <= 576px) {
      @content;
    }
  }

  @if $breakpoint == mobileL {
    @media only screen and (width <= 425px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (width <= 375px) {
      @content;
    }
  }
}

@mixin rtlAndRespond($breakpoint) {
  html[dir='rtl'] & {
    @include respond($breakpoint) {
      @content;
    }
  }
}

@mixin transition {
  transition: all 0.3s ease;
}

// Typography Mixins
@mixin tTitle_1 {
  font-size: 36px;
  line-height: 44px;
}

@mixin tTitle_1_demibold {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  @include respond(tablet) {
    font-size: 32px;
    line-height: 40px;
  }

  @include respond(mobileL) {
    font-size: 26px;
    line-height: 36px;
  }
}

@mixin tTitle_1_semibold {
  font-size: 24px;
  line-height: 32px;
}

@mixin tTitle_2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

@mixin tTitle_2_semibold {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  @include respond(tablet) {
    font-size: 22px;
    line-height: 32px;
  }

  @include respond(mobileL) {
    font-size: 20px;
    line-height: 28px;
  }
}

@mixin tTitle_2_demibold {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  @include respond(tablet) {
    font-size: 24px;
    line-height: 32px;
  }

  @include respond(mobileL) {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.44px;
  }
}

@mixin tTitle_2_medium {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

@mixin tTitle_3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

@mixin tTitle_3-semibold {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

@mixin tSubtitle-3-medium {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

@mixin tTitle_3-demibold {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  @include respond(mobileL) {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.24px;
  }
}

@mixin tTitle_3-medium {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

@mixin tTitle_4-medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

@mixin tTitle_3-regular {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

@mixin tButton {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

@mixin tButton_2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.12px;
}

@mixin t404 {
  font-style: normal;
  font-weight: 600;
  font-size: 128px;
  line-height: 128px;

  @include respond(tabletS) {
    font-size: 100px;
    line-height: 100px;
  }
}

@mixin tTitle_1_bold {
  font-weight: 700;
  font-size: 60px;
  line-height: 40px;
  letter-spacing: -0.44px;

  @include respond(mobileL) {
    font-size: 40px;
    line-height: 35px;
  }

  @include respond(mobile) {
    font-size: 32px;
  }
}

@mixin tTitle_1_banner {
  font-weight: 700;
  font-size: 68px;
  line-height: 80px;
}

@mixin tTitle_1_block {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;

  @include respond(laptop) {
    font-size: 48px;
    line-height: 60px;
  }

  @include respond(tablet) {
    font-size: 28px;
    line-height: 36px;
  }
}

@mixin tTitle_1_form {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;

  @include respond(tabletS) {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }
}

@mixin tTitle_2_bold {
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
}

@mixin tTitle_1_main {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
}

@mixin tTitle_2_main {
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
}

@mixin tTitle_2_Semibold {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}

@mixin tSubtitle {
  font-weight: 400;
  letter-spacing: -0.12px;
  font-size: 16px;
  line-height: 24px;
}

@mixin tSubtitle_Semibold {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.12px;
}

@mixin tSubtitle_Bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 5px;
}

@mixin tCaption {
  font-weight: 400;
  letter-spacing: -0.08px;
  font-size: 14px;
  line-height: 20px;
}

@mixin tCaption_Semibold {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

@mixin tTag {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

@mixin tChatTime {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@mixin text_regular {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

@mixin headline_regular {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

@mixin Land_28 {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}

@mixin Land_32 {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
}

@mixin Label {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.12px;
}

// SHADOWS  SHADOWS  SHADOWS  SHADOWS  SHADOWS  SHADOWS  SHADOWS  SHADOWS  SHADOWS
@mixin sElevation_1_bordered {
  box-shadow:
    0 0 0 1px var(--borderPrimary),
    0 8px 16px rgb(41 41 41 / 4%),
    0 0 6px rgb(41 41 41 / 4%);
}

@mixin sElevation_1 {
  box-shadow:
    0 8px 16px rgb(37 33 41 / 4%),
    0 0 6px rgb(37 33 41 / 4%);
}

@mixin sElevation_3 {
  box-shadow:
    0 8px 16px var(--shadow_2),
    0 0 6px var(--shadow_2);
}

@mixin sElevation_4 {
  box-shadow: 0 0 80px rgb(0 0 0 / 10%);
}

@mixin sElevation_6 {
  box-shadow:
    0 8px 16px rgb(41 41 41 / 4%),
    0 0 6px rgb(41 41 41 / 4%);
}

@mixin sElevation_5 {
  box-shadow:
    0 16px 32px rgb(37 33 41 / 8%),
    0 0 12px rgb(37 33 41 / 4%);
}

@mixin sElevation_7 {
  box-shadow:
    0 0 6px 0 rgb(41 41 41 / 4%),
    0 8px 16px 0 rgb(41 41 41 / 4%);
}

@mixin main_shadow {
  box-shadow:
    0 0 16px 0 rgb(37 33 41 / 4%),
    0 16px 28px 0 rgb(37 33 41 / 4%);
}

// CONTENTS ADAPTIVE PADDINGS

@mixin contentPaddings {
  @include respond(mobileL) {
    padding: 16px;
  }
}

@mixin spinnerAnimation {
  animation: spin 0.75s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }
}

@mixin placeholder {
  &::placeholder {
    @content;
  }
}

@mixin rtl {
  html[dir='rtl'] & {
    @content;
  }
}

@mixin ltr {
  html[dir='ltr'] & {
    @content;
  }
}

@mixin customScrollbar {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-left: 1px solid var(--aurora_select_border);
    border-right: 1px solid var(--aurora_select_border);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--aurora_text_secondary);
    border-radius: 6px;
    border: 3px solid transparent;
    background-clip: padding-box;
  }

  * {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-left: 1px solid var(--aurora_select_border);
      border-right: 1px solid var(--aurora_select_border);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--aurora_text_secondary);
      border-radius: 6px;
      width: 6px;
      border: 3.5px solid transparent;
      background-clip: padding-box;
    }
  }
}

@mixin contentWidth {
  width: 100%;
  max-width: 1280px;
  margin: auto;
}

@mixin contentWidth1 {
  width: 100%;
  max-width: 846px;
  margin: 0 auto;
  padding: 100px 0;

  @include respond(laptop) {
    max-width: 920px;
  }

  @include respond(tabletL) {
    padding: 16px;
  }
}

@mixin contentWidth2 {
  width: 100%;
  max-width: 846px;
  margin: 0 auto;

  @include respond(laptop) {
    max-width: 920px;
  }

  @include respond(tabletL) {
    padding: 0 16px;
  }
}

@mixin scanSwiperMixin {
  height: 246px !important;

  @include respond(laptop) {
    height: 200px !important;
  }

  @include respond(tablet) {
    height: 229px !important;
  }
}

@mixin scanSwiperPostMixin {
  width: 377px !important;
  height: 246px !important;

  @include respond(laptop) {
    width: 304px !important;
    height: 200px !important;
  }

  @include respond(tablet) {
    width: 348px !important;
    height: 229px !important;
  }

  @include respond(tablet) {
    width: 343px !important;
  }
}

@mixin solutionsBlock {
  min-height: 494px;
  height: 100%;
  padding: 48px;
  border-radius: 16px;
  overflow: hidden;

  @include respond(laptop) {
    max-width: 480px;
    padding: 32px;
  }

  @include respond(tablet) {
    max-width: none;
    width: 100%;
  }

  @include respond(tablet) {
    min-height: auto;
  }

  @include respond(tabletS) {
    border-radius: 0;
    padding: 32px 16px;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;

    @include respond(tabletS) {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }

  ul {
    margin: 0 0 80px;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include respond(tabletS) {
      margin-bottom: 32px;
    }

    li {
      display: flex;
      gap: 12px;

      svg {
        min-width: 24px;
      }

      p {
        margin: 0;
        max-width: 480px;

        @include tSubtitle;

        @include respond(laptop) {
          max-width: 367px;
        }

        @include respond(tablet) {
          max-width: none;
        }
      }
    }
  }

  .solutions_btn {
    position: absolute;
    bottom: 48px;

    @include respond(laptop) {
      bottom: 32px;
    }

    @include respond(tabletS) {
      width: 100%;
      position: relative;
      bottom: 0;
    }
  }
}

@mixin swiper($blockName: '', $shadowPad: 16px, $bg: var(--secondary)) {
  $pad: 26px;

  @if $blockName == 'media' {
    $pad: 54px 7px;
  }

  .swiper_responsive {
    .swiper_list {
      &:first-of-type {
        display: flex;

        @include respond(tabletS) {
          display: none;
        }
      }

      &:last-of-type {
        display: none;

        @include respond(tabletS) {
          display: flex;
        }
      }
    }
  }

  .swiper_list {
    display: flex;
    justify-content: space-between;
    background: $bg;
    padding: 32px;
    margin: -32px;

    @include respond(tablet) {
      flex-direction: column;
      gap: 32px;
    }

    @include respond(tabletS) {
      padding: 16px;
      margin: -16px;
    }

    .swiper_item_container {
      padding: 0;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .swiper_item {
        border-radius: 16px;
        overflow: hidden;
        padding: $pad;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--bg_6);

        @include sElevation_5;

        @content;

        h3 {
          margin-top: 16px;
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
        }

        p {
          margin: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.08px;
          color: var(--text_1);
        }
      }
    }
  }
}

@mixin referalBlock {
  width: 100%;
  min-height: 545px;
  border-radius: 16px;
  overflow: hidden;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 73px;

  @include respond(laptop) {
    padding: 32px;
    gap: 81px;
  }

  @include respond(tabletS) {
    padding: 32px 16px;
    border-radius: 0;
  }

  @include respond(mobileL) {
    gap: 32px;
  }

  .info {
    .title {
      h3 {
        margin-top: 0;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;

        @include respond(laptop) {
          margin-bottom: 16px;
          font-size: 36px;
          line-height: 46px;
        }

        @include respond(tabletS) {
          font-weight: 600;
          line-height: 44px;
        }
      }

      p {
        margin-top: 0;
        margin-bottom: 32px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.12px;
        color: var(--text_1);

        @include respond(tabletS) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.08px;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 20px;

      li {
        display: grid;
        grid-template-columns: max-content auto;
        align-items: center;
        gap: 12px;

        p {
          margin: 0;
        }
      }
    }
  }

  .buttons {
    z-index: 1;
    display: flex;
    gap: 16px;

    @include respond(tabletS) {
      flex-direction: column;
      gap: 8px;
    }

    a {
      display: block;

      &.referal_btn {
        width: 207px;

        @include respond(tabletS) {
          width: 100%;
        }
      }

      &.sign_up_btn {
        width: 169px;

        @include respond(tabletS) {
          width: 100%;
        }
      }

      &.connect_btn {
        width: 177px;

        @include respond(tabletS) {
          width: 100%;
        }
      }
    }
  }
}
